@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

*:focus {
  outline: none;
}
.overflow-anchor-none {
overflow-anchor: none;
}

.chat-widget {
  z-index: 10000;
}